//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { biTelephone, biChevronDown } from '@quasar/extras/bootstrap-icons'
import CallActionsBtn from '@/components/HeaderBar/CallActionsBtn'
import { uiStore } from '@/store'

export default {
  name: 'ChatButtons',
  components: {
    CallActionsBtn,
    'important-icon': createSVGComponent({ icon: 'important', size: 20 }),
    // TODO: migrate to '@/components/UI/ChatMenu.vue
    ChatMenu: () => import('@/components/HeaderBar/ChatMenu'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis'),
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
    isGroup: {
      type: Boolean,
      required: true,
    },
    canCall: {
      type: Boolean,
      required: false,
      default: false,
    },
    isForSneakpeek: {
      type: Boolean,
      required: true,
    },
    showSystembot: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    this.phoneIcon = biTelephone
    this.expandIcon = biChevronDown
  },
  data () {
    return {
      importantViewerActive: false,
    }
  },
  mounted () {
    this.handleImportantViewer = value => { this.importantViewerActive = !!value }
    ChatEventBus.$on(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
  },
  beforeDestroy () {
    ChatEventBus.$off(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
  },
  methods: {
    handleLeverClick () {
      uiStore.actions.clearSelectedMessages()

      ChatEventBus.$emit(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, !this.importantViewerActive)
    },
  },
}
